import GetInTouch from "./parts/GetInTouch";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Move() {
  const containerRef = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(".split", {
        duration: 0.4,
        opacity: 0,
        yPercent: 20,
        ease: "power1",
        stagger: 0.1,
        scrollTrigger: {
          trigger: ".split",
          start: "top 60%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
    }, containerRef);
    return () => ctx.revert();
  }, []);

  return (
    <section
      className="move"
      ref={containerRef}>
      <div className="layout-container">
        <div className="move__content">
          <div className="journey__text">
            <p className="section-title split">Knock-knock.</p>
          </div>
          <div className="journey__text">
            <p className="section-text split">
              My time books quickly. If you are thrilled to start working together <br />
              don’t hesitate and reach me out. We will find the best design <br />
              solution that will save you money, time and brain cells.
            </p>
          </div>
          <div className="d-flex split">
            <GetInTouch />
          </div>
        </div>
      </div>
    </section>
  );
}
