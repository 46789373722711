import AddIcon from "../../assets/icons/AddIcon";
export default function GetInTouch({ latte }) {
  return (
    <a
      href="mailto:martinov.uxui@gmail.com"
      className={`get-in-touch ${latte ? "latte" : ""}`}>
      Get in touch <AddIcon color={latte ? "latte" : ""} />
    </a>
  );
}
