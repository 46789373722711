import { useState, useEffect } from "react";

export default function ZurichTime() {
  const [time, setTime] = useState("");

  useEffect(() => {
    const updateZurichTime = () => {
      const options = {
        timeZone: "Europe/Zurich",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      const time = new Intl.DateTimeFormat("ru-RU", options).format(new Date());
      setTime(time);
    };
    const intervalId = setInterval(updateZurichTime, 1000);
    updateZurichTime();
    return () => clearInterval(intervalId);
  }, []);

  return time;
}
