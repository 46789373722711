import Equalizer from "./parts/Equalizer";
import GetInTouch from "./parts/GetInTouch";
import { useState, useRef } from "react";

export default function Header() {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio("/wonder.mp3"));

  const handleAudioToggle = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      audio.currentTime = 0;
      audio.play();
      setIsPlaying(true);
    }

    audio.onended = () => {
      setIsPlaying(false);
    };
  };

  return (
    <header
      className="header"
      data-scroll
      data-scroll-sticky
      data-scroll-target="#App">
      <div className="layout-container header-scroll header__container">
        <div>
          <p className="header__title">Pavel Martinov</p>
          <p className="header__text hide-md">UI/UX & Product Design Lead</p>
        </div>
        <div className="">
          <button
            className="header__text"
            onClick={handleAudioToggle}>
            <Equalizer classStyle={isPlaying ? "active" : ""} />
          </button>
        </div>
        <div className="t-right">
          <GetInTouch latte />
          <p className="header__text hide-md">Reply 1-2 business days</p>
        </div>
      </div>
    </header>
  );
}
