import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import AddIcon from "../assets/icons/AddIcon";

gsap.registerPlugin(ScrollTrigger, useGSAP);

function CaseItem({ img, smaller, title, text }) {
  return (
    <div className={`cases__item ${smaller ? "smaller" : ""}`}>
      <div className="cases__item-img">
        <img
          src={`/images/cases/case-${img}.webp`}
          alt=""
        />
        <div className="cases__item-btn">
          <div className="hover">
            <AddIcon />
          </div>
          <div className="center-svg">
            <AddIcon />
          </div>
        </div>
      </div>
      <p>{title}</p>
      <p>{text}</p>
    </div>
  );
}

export default function Cases() {
  const container = useRef(null);

  useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1024px)", () => {
      const panels = container.current.querySelectorAll(".cases__slide");

      panels.forEach((panel) => {
        const items = panel.querySelectorAll(".cases__item");

        items.forEach((item) => {
          gsap.fromTo(
            item,
            {
              y: item.classList.contains("smaller") ? "100%" : "60%",
            },
            {
              y: "0%",
              opacity: 1,
              duration: 1,
              scrollTrigger: {
                trigger: panel,
                start: "top bottom",
                end: "center center",
                scrub: true,
                toggleActions: "play none none reverse",
                invalidateOnRefresh: true,
              },
            }
          );
          const img = item.querySelector(".cases__item-img");
          if (img) {
            gsap.fromTo(
              img,
              {
                y: "-100%",
              },
              {
                y: "0%",
                duration: 1,
                scrollTrigger: {
                  trigger: panel,
                  start: "top bottom",
                  end: "center center",
                  scrub: true,
                  toggleActions: "play none none reverse",
                  invalidateOnRefresh: true,
                },
              }
            );
          }
        });
      });

      ScrollTrigger.refresh();
    });

    return () => mm.revert();
  }, []);

  return (
    <section className="cases">
      <div className="layout-container">
        <div
          className="cases__slides"
          ref={container}>
          <div className="cases__slide">
            <CaseItem
              img={1}
              text="Fintech, Private Banking"
              title="Redefining Private Banking for High Net Worth"
            />
            <CaseItem
              smaller
              title="Reclaiming Privacy in the Digital Workspace"
              text="SaaS, Data Security"
              img={2}
            />
          </div>
          <div className="cases__slide">
            <CaseItem
              smaller
              text="Sports Tech, AI Analysis"
              title="Transforming Talent Potential with AI-Driven Soccer Insights"
              img={3}
            />
            <CaseItem
              img={4}
              text="SaaS, Company Data"
              title="Revolutionizing Access to UK Market Intelligence"
            />
          </div>
          <div className="cases__slide">
            <CaseItem
              img={6}
              text="Gambling, High-Stakes Casino"
              title="A Private Casino Experience Like No Other"
            />
            <CaseItem
              title="Effortless Crypto Management and Spending"
              text="Fintech, Crypto Wallet"
              smaller
              img={5}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
