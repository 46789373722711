import DividerPlus from "./DividerPlus";
import ZurichTime from "./parts/ZurichTime";
import GetInTouch from "./parts/GetInTouch";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="layout-container">
        <DividerPlus position="center" />
        <div className="header__container">
          <div className="">
            <p className="header__title">Pavel Martinov</p>
            <p className="header__text">UI/UX & Product Design Lead</p>
          </div>
          <div className="">
            <p className="header__title">Switzerland, Zurich</p>
            <p className="header__text">
              <ZurichTime />
            </p>
          </div>
          <div className="t-right">
            <GetInTouch />
            <p className="header__text">Reply 1-2 business days</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
