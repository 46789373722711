import DividerPlus from "./DividerPlus";
import { useEffect, useRef } from "react";
import gsap from "gsap";

export default function Areas() {
  const containerRef = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(".split-small", {
        duration: 0.4,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        scrollTrigger: {
          trigger: ".split-small",
          start: "top 80%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
      gsap.from(".split", {
        duration: 0.4,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        stagger: 0.1,
        scrollTrigger: {
          trigger: ".split",
          start: "top 80%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
    }, containerRef);
    return () => ctx.revert();
  }, []);

  return (
    <section
      className="journey areas"
      ref={containerRef}>
      <div className="layout-container journey__top">
        <div className="journey__text">
          <p className="section-title-small split-small">AREAS OF EXPERTISE</p>
        </div>
        <div>
          <div className="journey__top-content">
            <div className="journey__text mobile">
              <p>
                <span>High-end websites, branding & advanced</span> <span>prototyping for startups.</span> Expert in ux/ui design, based in Switzerland. Working remotely, worldwide.
              </p>
            </div>

            <div className="journey__text desktop">
              <p className="split">
                <span>High-end websites, branding & advanced</span>
              </p>
            </div>
            <div className="journey__text desktop">
              <p className="split">
                <span>prototyping for startups.</span> Expert in ux/ui design, based in
              </p>
            </div>
            <div className="journey__text desktop">
              <p className="split t-left">Switzerland. Working remotely, worldwide.</p>
            </div>
          </div>
          <DividerPlus
            latte
            position="left"
          />
        </div>
      </div>
      <div className="areas__list layout-container">
        <p className="section-text">
          This is what I focus on. <br />
          Additional services are available upon request.
        </p>
        <div className="areas__list-values">
          <p>UI+UX</p>
          <p>Prototyping</p>
          <p>Web Design</p>
          <p>Brand Identity</p>
          <p>Pitch Decks</p>
          <p>Design Systems</p>
          <p>Webflow Development</p>
        </div>
      </div>
    </section>
  );
}
