import { useLayoutEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const useLocoScroll = (start) => {
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    if (!start) return;

    const scrollEl = document.querySelector(".App");
    const header = document.querySelector(".header-scroll");
    let lastScrollY = 0;

    let locoScroll = new LocomotiveScroll({
      el: scrollEl,
      smooth: true,
      multiplier: 1,
      smoothMobile: true,
      tablet: {
        breakpoint: 0,
      },
    });

    locoScroll.on("scroll", ({ scroll }) => {
      const currentScrollY = scroll.y;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        header?.classList.add("hidden");
        header?.classList.remove("visible");
      } else {
        header?.classList.add("visible");
        header?.classList.remove("hidden");
      }

      lastScrollY = currentScrollY;
    });

    locoScroll.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(scrollEl, {
      scrollTop(value) {
        if (locoScroll) {
          return arguments.length ? locoScroll.scrollTo(value, { duration: 0, disableLerp: true }) : locoScroll.scroll.instance.scroll.y;
        }
        return null;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      pinType: document.querySelector(".App").style.transform ? "transform" : "fixed",
    });

    const lsUpdate = () => {
      if (locoScroll) {
        locoScroll.update();
      }
    };

    ScrollTrigger.defaults({
      scroller: document.documentElement.classList.contains("has-scroll-smooth") && scrollEl,
    });

    ScrollTrigger.addEventListener("refresh", lsUpdate);

    return () => {
      locoScroll.destroy();
      ScrollTrigger.removeEventListener("refresh", lsUpdate);
    };
  }, [start]);
};

export default useLocoScroll;
