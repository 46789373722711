import HeroSlider from "./HeroSlider";
import { useEffect, useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Hero() {
  useEffect(() => {
    let mm = gsap.matchMedia();
    mm.add("(min-width: 1024px)", () => {
      gsap.from(".hero .split", {
        duration: 0.7,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".split",
          start: "90% bottom",
          end: "bottom 20%",
          toggleActions: "play reverse play reverse",
        },
      });
    });
    mm.add("(max-width: 1023px)", () => {
      gsap.from(".hero .split", {
        duration: 0.7,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".split",
          start: "top center",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
    });
    return () => mm.revert();
  }, []);

  return (
    <section className="hero">
      <div className="hero__slider-block">
        <HeroSlider />
      </div>
      <div className="hero__content layout-container">
        <div className="hero__text">
          <p className="section-title split">
            Business oriented <br />
            UX/UI solutions.{" "}
          </p>
        </div>
        <div className="hero__text">
          <p className="section-text split">
            I have spent 10+ years designing and re-designing digital solutions, <br />
            and learned what makes great businesses stay in business.
          </p>
        </div>
      </div>
    </section>
  );
}
