export default function DividerPlus({ position, mb, latte }) {
  return (
    <div className={`dividerPlus ${latte ? "latte" : ""} ${mb ? "mb" : ""} ${position}`}>
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 5.88462V5.11538H4.61538V0.5H5.38462V5.11538H10V5.88462H5.38462V10.5H4.61538V5.88462H0Z"
          fill="#0B0B0B"
        />
      </svg>
    </div>
  );
}
