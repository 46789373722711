import Areas from "../components/Areas";
import Cases from "../components/Cases";
import GradientLines from "../components/GradientLines";
import Hero from "../components/Hero";
import HeroVideo from "../components/HeroVideo";
import HeroVideoCursor from "../components/HeroVideoCursor";
import Journey from "../components/Journey";
import Layout from "../components/Layout";
import Move from "../components/Move";
import SmoothScroll from "../components/SmoothScroll";
import TestHero from "../components/test/TestHero";

export default function Homepage() {
  return (
    <Layout>
      {/* <HeroVideoCursor /> */}
      <Hero />
      <GradientLines />
      {/* <HeroVideo /> */}
      <Journey />
      <Cases />
      <Areas />
      <GradientLines />
      <Move />
    </Layout>
  );
}
