import React from "react";

const GradientLines = () => {
  const totalDivs = 31;

  return (
    <div className="gradient-lines-container">
      {Array.from({ length: totalDivs }).map((_, index) => {
        const opacity = index / (totalDivs - 1);
        return (
          <div
            key={index}
            className="gradient-line"
            style={{
              backgroundColor: `rgba(209, 209, 214, ${opacity})`,
            }}></div>
        );
      })}
    </div>
  );
};

export default GradientLines;
