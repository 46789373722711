import { useEffect, useRef } from "react";
import DividerPlus from "./DividerPlus";
import gsap from "gsap";

export default function Journey() {
  const containerRef = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from(".split-small", {
        duration: 0.4,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        scrollTrigger: {
          trigger: ".split-small",
          start: "top 80%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
      gsap.from(".split", {
        duration: 0.4,
        opacity: 0,
        yPercent: 50,
        ease: "power1",
        stagger: 0.1,
        scrollTrigger: {
          trigger: ".split",
          start: "top 80%",
          end: "bottom top",
          toggleActions: "play reverse play reverse",
        },
      });
    }, containerRef);
    return () => ctx.revert();
  }, []);

  return (
    <section
      className="journey"
      ref={containerRef}>
      <div className="layout-container journey__top">
        <div className="journey__text">
          <p className="section-title-small split-small">CHALLENGER AT HEART, DESIGNER BY NATURE</p>
        </div>
        <div className="journey__top-content">
          <DividerPlus
            position="left"
            mb
          />
          <div className="journey__text mobile">
            <p>
              <span>If you are a pragmatic business seeking</span> <span>a UX/UI professional my congratulations—you’ve found</span> <span>me.</span> My goal is to turn complicated ideas into simple, high-converting interfaces that drive revenue.
            </p>
          </div>
          <div className="journey__text desktop">
            <p className="split pr">
              <span>If you are a pragmatic business seeking</span>
            </p>
          </div>
          <div className="journey__text desktop">
            <p className="split t-left">
              <span>a UX/UI professional my congratulations—you’ve found</span>
            </p>
          </div>
          <div className="journey__text desktop">
            <p className="split t-left">
              <span>me.</span> My goal is to turn complicated ideas into simple,{" "}
            </p>
          </div>
          <div className="journey__text desktop">
            <p className="split t-left">high-converting interfaces that drive revenue.</p>
          </div>
        </div>
      </div>
    </section>
  );
}
