import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";
import useLocoScroll from "./hooks/useLocoScroll";
export default function App() {
  useLocoScroll(true);

  return (
    <div
      data-scroll-container
      className="App"
      id="App">
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}
