export default function AddIcon({ color }) {
  const hex = color === "latte" ? "#D1D1D6" : "#0b0b0b";
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.38462V4.61538H4.61538V0H5.38462V4.61538H10V5.38462H5.38462V10H4.61538V5.38462H0Z"
        fill={hex}
      />
    </svg>
  );
}
