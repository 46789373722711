import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { useEffect, useState } from "react";
import { useWindowWidth } from "../helpers";

const commonParams = {
  grabCursor: false,
  allowTouchMove: false,
  loop: true,
  modules: [Autoplay],
  freeMode: true,
};
const desktopParams = {
  ...commonParams,
  autoplay: {
    delay: 0,
  },
  slidesPerView: 3,
  speed: 6000,
  breakpoints: {
    2000: {
      slidesPerView: 5,
    },
  },
};

const tabletParams = {
  ...commonParams,
  slidesPerView: 1,
  speed: 0,
  autoplay: {
    delay: 500,
    disableOnInteraction: false,
    waitForTransition: false,
  },
};

export default function HeroSlider() {
  const [params, setParams] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (params) {
      if ((windowWidth >= 1024 && params !== desktopParams) || (windowWidth < 1024 && params !== tabletParams)) {
        setParams(false);
      }
    } else {
      setParams(windowWidth >= 1024 ? desktopParams : tabletParams);
    }
  }, [windowWidth, params]);

  return (
    <div className="hero__slider">
      {params && (
        <Swiper {...params}>
          {Array.from({ length: 12 }, (_, index) => (
            <SwiperSlide key={index}>
              <div className="hero__slide">
                <img
                  src={`/images/hero/slide-${index}.webp`}
                  alt={`Slide ${index}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
